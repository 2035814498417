import React from 'react';
import Head from 'next/head';
import favicon from '../../../public/assets/images/brand-logos/favicon.ico';

const Seo = ({ title }) => {
  const pageTitle = `Spend Vizor - ${title}`;

  return (
    <Head>
      <title>{pageTitle}</title>
      <link rel="icon" href={favicon.src} />
      <meta name="description" content="Spend Vizor" />
      <meta name="author" content="Spruko Technologies Private Limited" />
      <meta
        name="keywords"
        content="tailwind nextjs, dashboard tailwind, tailwind admin, nextjs tailwind, tailwind in nextjs, admin dashboard tailwind, tailwind dashboards, tailwind template admin, tailwind and nextjs, admin panel, template dashboard, admin dashboard template, admin panel template"
      />
    </Head>
  );
};

export default Seo;
