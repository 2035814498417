import Seo from '@/shared/layout-components/seo/seo'
import Link from 'next/link';
import React, { Fragment, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Signincover = () => {

    const [passwordshow1, setpasswordshow1] = useState(false);

  return (
    <Fragment>
         <HelmetProvider>
                <Helmet>
                    <body className="bg-white dark:!bg-bodybg"></body>
                </Helmet>
        <Seo title={"Signin-cover"}/>
    <div className="grid grid-cols-12 authentication mx-0 text-defaulttextcolor text-defaultsize">

    <div className="xxl:col-span-7 xl:col-span-7 lg:col-span-12 col-span-12">
        <div className="flex justify-center items-center h-full">
          <div className="xxl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-2"></div>
          <div className="xxl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-8 col-span-12">
                <div className="p-[3rem] w-[400px]">
                    <div className="mb-4">
                        <Link aria-label="anchor" href="/components/dashboards/crm/">
                            <img src="../../../../assets/images/brand-logos/desktop-login.png" alt="" className="authentication-brand desktop-login"/>
                            <img src="../../../../assets/images/brand-logos/desktop-dark.png" alt="" className="authentication-brand desktop-dark"/>
                        </Link>
                    </div>
                    <p className="h5 font-semibold mb-2 text-center">Sign In</p>
                    <div className="grid grid-cols-12 gap-y-4">
                        <div className="xl:col-span-12 col-span-12 mt-0">
                            <label htmlFor="signin-username" className="form-label text-default">User Name</label>
                            <input type="text" className="form-control form-control-lg w-full !rounded-md" id="signin-username" placeholder="user name"/>
                        </div>
                        <div className="xl:col-span-12 col-span-12 mb-4">
                            <label htmlFor="signin-password" className="form-label text-default block">Password<Link href="/components/authentication/reset-password/reset-cover" className="ltr:float-right rtl:float-left text-danger">Forget password ?</Link></label>
                            <div className="input-group">
                                <input type={(passwordshow1) ? 'text' : "password"} className="form-control form-control-lg !rounded-e-none" id="signin-password" placeholder="password"/>
                                <button aria-label="button" type="button" className="ti-btn ti-btn-light !rounded-s-none !mb-0" onClick={()=>setpasswordshow1(!passwordshow1)} id="button-addon2"><i className={`${passwordshow1 ? 'ri-eye-line' : 'ri-eye-off-line'} align-middle`}></i></button>
                            </div>
                            <div className="mt-2">
                                <div className="form-check !ps-0">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                                    <label className="form-check-label text-[#8c9097] dark:text-white/50 font-normal" htmlFor="defaultCheck1">
                                        Remember password ?
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-12 col-span-12 grid mt-2">
                            <Link href="/dashboard/" className="ti-btn ti-btn-lg bg-primary text-white !font-medium dark:border-defaultborder/10">Sign In</Link>
                        </div>
                    </div>
                    <div className="text-center">
                        <p className="text-[0.75rem] text-[#8c9097] dark:text-white/50 mt-4">Dont have an account? <Link href="/components/authentication/sign-up/signup-cover" className="text-primary">Sign Up</Link></p>
                    </div>
                </div>
            </div>
            <div className="xxl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-2"></div>
        </div>
    </div>
    <div className="xxl:col-span-5 xl:col-span-5 lg:col-span-5 col-span-12 xl:block hidden px-0">
        <div className="authentication-cover">
            <div className="aunthentication-cover-content rounded">
                <div className="swiper keyboard-control">
                <Swiper spaceBetween={30} navigation={true} centeredSlides={true} autoplay={{ delay: 2500, disableOnInteraction: false, }} pagination={{ clickable: true, }} modules={[Pagination, Autoplay, Navigation]} className="mySwiper">
                        <SwiperSlide>
                            <div className="text-white text-center p-[3rem] flex items-center justify-center">
                                <div>
                                    <div className="mb-[3rem]">
                                        <img src="../../../../assets/images/authentication/2.png" className="authentication-image" alt=""/>
                                    </div>
                                    <h6 className="font-semibold text-[1rem]">Sign In</h6>
                                    <p className="font-normal text-[.875rem] opacity-[0.7]"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa eligendi expedita aliquam quaerat nulla voluptas facilis. Porro rem voluptates possimus, ad, autem quae culpa architecto, quam labore blanditiis at ratione.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="text-white text-center p-[3rem] flex items-center justify-center">
                                <div>
                                    <div className="mb-[3rem]">
                                        <img src="../../../../assets/images/authentication/3.png" className="authentication-image" alt=""/>
                                    </div>
                                    <h6 className="font-semibold text-[1rem]">Sign In</h6>
                                    <p className="font-normal text-[.875rem] opacity-[0.7]"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa eligendi expedita aliquam quaerat nulla voluptas facilis. Porro rem voluptates possimus, ad, autem quae culpa architecto, quam labore blanditiis at ratione.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="text-white text-center p-[3rem] flex items-center justify-center">
                                <div>
                                    <div className="mb-[3rem]">
                                        <img src="../../../../assets/images/authentication/2.png" className="authentication-image" alt=""/>
                                    </div>
                                    <h6 className="font-semibold text-[1rem]">Sign In</h6>
                                    <p className="font-normal text-[.875rem] opacity-[0.7]"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa eligendi expedita aliquam quaerat nulla voluptas facilis. Porro rem voluptates possimus, ad, autem quae culpa architecto, quam labore blanditiis at ratione.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </div>

</div>
</HelmetProvider>
</Fragment>
  )
}

Signincover.layout = "Authenticationlayout"

export default Signincover